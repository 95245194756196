import React from 'react';
import {useIntl, FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl";
import SEO from "../components/seo";
import Layout from '../components/Layout';

// Assets
    import ImgArtists from '../images/art/artists.jpg';
    import CalendarWenders from '../images/calendar/wenders.jpg';
    import CalendarKruger from '../images/calendar/kruger.jpg';
    import CalendarKarlson from '../images/calendar/karlson.jpg';
    import CalendarAbramovic from '../images/calendar/abramovic.jpg';
    import CalendarKristo from '../images/calendar/kristo.jpg';
    import CalendarScher from '../images/calendar/scher.jpg';
    import CalendarBologna from '../images/calendar/bologna.jpg';
    import CalendarBigactive from '../images/calendar/bigactive.jpg';
    import CalendarBjork from '../images/calendar/bjork.jpg';
    import CalendarBirn from '../images/calendar/birn.jpg';
    import CalendarBantjes from '../images/calendar/bantjes.jpg';
    import CalendarKidd from '../images/calendar/kidd.jpg';

    import limpOne from '../images/limpossible/limpossible-1.jpg';
    import limpTwo from '../images/limpossible/limpossible-2.jpg';
    import limpThree from '../images/limpossible/limpossible-3.jpg';
    import limpFour from '../images/limpossible/limpossible-4.jpg';
    import limpFive from '../images/limpossible/limpossible-5.jpg';
    import limpSix from '../images/limpossible/limpossible-6.jpg';

    import artOtherOne from '../images/art/art-thats-not-all-1.jpg';
    import artOtherTwo from '../images/art/art-thats-not-all-2.jpg';


const Art = (props) => {

    const intl = useIntl();

    return (
        <Layout location={props.uri} language={props.language}>
            <div className="page__container-inner">

                <SEO 
                    title={intl.formatMessage({ id: "title.art" })}
                    description={intl.formatMessage({ id: "description.art" })}
                    lang={intl.locale}
                />
   
                <div className="page__sections-container">
                    <article className="page__section page__section--first">

                        <div className="container">
                            <header className="art__page-intro-container">
                                <h1 className="art__page-title"><FormattedHTMLMessage id="art.main-title" /></h1>
                                <p className="art__page-intro-text"><FormattedMessage id="art.intro-text-p1" /></p>

                                <p className="art__page-intro-text"><FormattedHTMLMessage id="art.intro-text-p2" /></p>
                            </header>
                        </div>   

                        <div className="art-page__intro-image-container">
                            <img src={ImgArtists} alt="Great people create art" />
                        </div>

                    </article>

                    <article className="page__section calendar">

                        <div className="split-screen container">

                            <header className="split-screen__item calendar__intro">

                                <h2 className="calendar__title"><FormattedHTMLMessage id="art.calendar-title" /></h2>

                                <div className="calendar__intro-images-container">

                                    <div className="calendar__item-container">
                                        <img className="calendar__image" src={CalendarWenders} alt="Wim Wenders" />
                                        <p className="calendar__image-caption">Wim Wenders</p>
                                    </div>

                                    <div className="calendar__item-container">
                                        <img className="calendar__image" src={CalendarKruger} alt="Barbara Kruger" />
                                        <p className="calendar__image-caption">Barbara Kruger</p>
                                    </div>

                                </div>

                                <p className="calendar__intro-text"><FormattedHTMLMessage id="art.calendar-intro-text" /></p>

                            </header>

                            <div className="split-screen__item calendar__main-content">

                                <div className="calendar__item-container">
                                    <img className="calendar__image" src={CalendarKarlson} alt="Karlssonwilker" />
                                    <p className="calendar__image-caption">Karlssonwilker</p>
                                </div>

                                <div className="calendar__item-container">
                                    <img className="calendar__image" src={CalendarAbramovic} alt="Marina Abramovic" />
                                    <p className="calendar__image-caption">Marina Abramovic</p>
                                </div>

                                <div className="calendar__item-container">
                                    <img className="calendar__image" src={CalendarKristo} alt="Christo and Jeanne-Claude" />
                                    <p className="calendar__image-caption">Christo and Jeanne-Claude</p>
                                </div>

                                <div className="calendar__item-container">
                                    <img className="calendar__image" src={CalendarScher} alt="Paula Scher" />
                                    <p className="calendar__image-caption">Paula Scher</p>
                                </div>

                                <div className="calendar__item-container">
                                    <img className="calendar__image" src={CalendarBologna} alt="Matteo Bologna" />
                                    <p className="calendar__image-caption">Matteo Bologna</p>
                                </div>

                                <div className="calendar__item-container">
                                    <img className="calendar__image" src={CalendarBigactive} alt="Big Active" />
                                    <p className="calendar__image-caption">Big Active</p>
                                </div>

                                <div className="calendar__item-container">
                                    <img className="calendar__image" src={CalendarBjork} alt="Bjork" />
                                    <p className="calendar__image-caption">Bjork</p>
                                </div>

                                <div className="calendar__item-container">
                                    <img className="calendar__image" src={CalendarBirn} alt="David Byrne" />
                                    <p className="calendar__image-caption">David Byrne</p>
                                </div>

                                <div className="calendar__item-container">
                                    <img className="calendar__image" src={CalendarBantjes} alt="Marian Bantjes" />
                                    <p className="calendar__image-caption">Marian Bantjes</p>
                                </div>

                                <div className="calendar__item-container">
                                    <img className="calendar__image" src={CalendarKidd} alt="Chip Kidd" />
                                    <p className="calendar__image-caption">Chip Kidd</p>
                                </div>

                                <div className="calendar__item-container calendar__item-container--x2">
                                    <p className="calendar__item-text"><FormattedMessage id="art.calendar-outro-text" /></p>
                                </div>

                            </div>

                        </div>


                    </article>

                    <article className="page__section limpossible">

                        <div className="container">

                            <div className="limpossible__intro">

                                <div className="limpossible__intro-main-content">

                                    <h2 className="limpossible__title"><FormattedHTMLMessage id="art.limpossible-title" /></h2>

                                    <div className="limpossible__intro-text">
                                        <p><FormattedHTMLMessage id="art.limpossible-intro-p1" /></p>

                                        <p><FormattedHTMLMessage id="art.limpossible-intro-p2" /></p>
                                    </div>

                                </div>

                                <div className="limpossible__image-container">
                                    <img className="limpossible__image" src={limpOne} alt="L'imposssible" />
                                </div>
                                
                            </div>
                                
                            <div className="limpossible__images-grid">

                                <div className="limpossible__image-container">
                                    <img className="limpossible__image" src={limpTwo} alt="L'imposssible" />
                                </div>
                                
                                <div className="limpossible__image-container">
                                    <img className="limpossible__image" src={limpThree} alt="L'imposssible" />
                                </div>

                                <div className="limpossible__image-container">
                                    <img className="limpossible__image" src={limpFour} alt="L'imposssible" />
                                </div>

                                <div className="limpossible__image-container">
                                    <img className="limpossible__image" src={limpFive} alt="L'imposssible" />
                                </div>

                                <div className="limpossible__image-container">
                                    <img className="limpossible__image" src={limpSix} alt="L'imposssible" />
                                </div>

                            </div>

                        </div>

                    </article>

                    <article className="page__section thats-not-all">

                        <div className="split-screen container">

                            <div className="split-screen__item thats-not-all__intro">

                                <div className="thats-not-all__intro-text-container">
                                    <h2 className="thats-not-all__title"><FormattedHTMLMessage id="art.thats-not-all-title" /></h2>
                            
                                    <p className="thats-not-all__intro-text"><FormattedMessage id="art.thats-not-all-text" /></p>
                                </div>

                                <div className="thats-not-all__intro-image-container">
                                    <img src={artOtherOne} alt="Publikum Art - That's not all" />
                                </div>

                            </div>

                            <div className="split-screen__item thats-not-all__second-page">
                                <img src={artOtherTwo} alt="Publikum Art - That's not all" />
                            </div>

                        </div>

                    </article>
                </div>


            </div>
        </Layout>
    );

}

export default Art;
